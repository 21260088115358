const Input = ({ className = "", labelName = "", ...rest }) => {
  return (
    <div className="w-full flex flex-col justify-start">
      {labelName !== "" && (
        <label className="tg-lgray font-semibold">{labelName}</label>
      )}
      <input
        className={`w-full  outline-none rounded-md border border-2 px-2 placeholder:tg-lgray ${className}
           file:variant-default-btn file:py-[4px] file:mt-[2px] file:mr-3
        ${rest.type === "file" ? "" : "tg-lgray"}
        `}
        {...rest}
      />
    </div>
  );
};
export default Input;
