import { navigationLinks } from "../Nav/links";

export const footerLinks = [
  { name: "Links", subLinks: [...navigationLinks] },
  {
    name: "Location",
    subLinks: [
      { name: "Biratnagar", href: "/" },
      { name: "Pokhara", href: "/" },
      { name: "Damuli", href: "/" },
    ],
  },
  {
    name: "Blog",
    subLinks: [
      { name: "Virtual Tour", href: "/" },
      { name: "How to achieve goals", href: "/" },
      { name: "Why XIEC", href: "/" },
      { name: "Story of youth", href: "/" },
    ],
  },
];
