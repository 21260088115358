import React, { Suspense } from "react";
import PageRoutes from "./routes";
import Loading from "./components/Loading";
function App() {
  return (
    <main>
      <Suspense
        fallback={
          <Loading
            className={"h-screen w-screen flex justify-cener items-center"}
          />
        }
      >
        <PageRoutes />
      </Suspense>
    </main>
  );
}

export default App;
