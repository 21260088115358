import { useRef } from "react";

const FileUploader = ({ setter, label }) => {
  const fileRef = useRef(null);
  function handleClick(e) {
    fileRef.current.click();
  }
  function handleChange(e) {
    const file = e.target.files[0];
    // console.log(file);
    setter(file);
  }
  return (
    <>
      <label
        onClick={handleClick}
        className="tg-lgray font-semibold cursor-pointer"
      >
        {label}
      </label>
      <input
        type="file"
        onChange={handleChange}
        ref={fileRef}
        className="hidden"
      />
    </>
  );
};
export default FileUploader;
