export const navigationLinks = [
  {
    href: "/",
    name: "Home",
    private: false,
  },
  {
    href: "/blogs",
    name: "Blog",
    private: false,
  },
  {
    href: "/branch",
    name: "Branch",
    private: false,
  },
  {
    href: "/abroad-study",
    name: "Study Abroad",
    private: false,
  },
];