import { apiSlice } from "../../utils/api";

export const extendedSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadFile: builder.mutation({
            query: ({ file }) => ({
                url: "/api/v1/upload",
                method: "POST",
                body: file,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }),
        }),
        updateProfile: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/users",
                method: "PUT",
                body: {
                    ...payload
                }
            }),
        }),
        getUsers: builder.query({
            query: ({ page, query }) => ({
                url: `/api/v1/users?page=${page}&name[regex]=${query}`,
                method: "GET",
            }),
            transformResponse: (res) => {
                const students = res.data.filter((doc) => doc.role === "student");
                const admins = res.data.filter((doc) => doc.role === "admin");
                return {
                    users: res.data,
                    count: res.count,
                    total: res.total,
                    pagination: res.pagination,
                    totalStudents: students.length,
                    totalAdmins: admins.length,
                };
            },
            transformErrorResponse: ({ data }) => {
                return { ...data };
            },
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/users/${id}`,
                method: "DELETE",
            })
        }),
    }),
});
export const {
    useDeleteUserMutation,
    useGetUsersQuery,
    useUpdateProfileMutation,
    useUploadFileMutation
} = extendedSlice;
