import { classNames } from "../../utils/classes";
import { MdSpaceDashboard } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { HiUsers } from "react-icons/hi";
import { GiGraduateCap } from "react-icons/gi";
import { BiLogOut } from "react-icons/bi";
import Link from "../../components/Button/LinkButton";
import { MdEvent } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../store/auth/auth_slice";
const iconsForAdmin = [
  { name: MdSpaceDashboard, href: "/dashboard/admin/home" },
  { name: TiDocumentText, href: "/dashboard/admin/appoinment" },
  { name: HiUsers, href: "/dashboard/admin/users" },
  { name: GiGraduateCap, href: "/dashboard/admin/countries" },
  { name: MdEvent, href: "/dashboard/admin/events" },
];
const iconsForStudent = [
  { name: MdSpaceDashboard, href: "/dashboard/student/home" },
  { name: GiGraduateCap, href: "/dashboard/student/countries" },
  { name: MdEvent, href: "/dashboard/student/events" },
];

const SideBar = () => {
  const { userInfo } = useSelector(authSelector);
  const navigate = useNavigate();
  let pathName = window.location.pathname;
  let icons = userInfo?.role === "admin" ? iconsForAdmin : iconsForStudent;

  function logoutAccount() {
    localStorage.clear();
    navigate("/")
  }

  return (
    <div className="px-4 h-full border flex flex-col pt-6 justify-between items-center">
      <div className="flex flex-col space-y-6">
        {icons.map((icon, i) => {
          let Icon = icon.name;
          return (
            <Link as="button" to={icon.href} key={i}>
              <Icon
                className={classNames(
                  "h-[40px] w-[40px]",
                  "hover:text-primary",
                  pathName === icon.href ? "text-primary " : "text-gray-400 "
                )}
              />
            </Link>
            // {/* <TiDocumentText className="text-gray-400 h-[40px] w-[40px]" />
            // <HiUsers className="text-gray-400 h-[40px] w-[40px]" />
            // <GiGraduateCap className="text-gray-400 h-[40px] w-[40px]" /> */}
          );
        })}
      </div>
      <div className="pb-4">
        <BiLogOut
          className={classNames(
            "text-gray-400 hover:text-red cursor-pointer",
            "h-[40px] w-[40px]"
          )}
          onClick={logoutAccount}
        />
      </div>
    </div>
  );
};
export default SideBar;
