import { useState, useRef } from "react";
import Input from "../../../../components/Input/Input";
import Modal from "../../../../components/Modal";
import Avatar from "../../../../components/Avatar";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../store/auth/auth_slice";

const Setting = ({ isOpen, onClose, userInfo, setter, isLoading, submitFormHandler }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      setter={setter}
      modalRef={cancelButtonRef}
      children={
        <>
          <UserUpdateForm
            cancelRef={cancelButtonRef}
            formCloseHandler={onClose}
            submitHandler={submitFormHandler}
            isLoading={isLoading}
            userInfo={userInfo}
          />{" "}
        </>
      }
      showButton={false}
    />
  );
};
export default Setting;
const UserUpdateForm = ({
  cancelRef,
  formCloseHandler,
  submitHandler,
  isLoading,
  userInfo
}) => {
  const [file, setFile] = useState(null);
  const [formState, setFormState] = useState({
    name: userInfo?.name ?? "",
    branch: userInfo?.branch ?? "",
  });

  return (
    <>
      <div className="py-3 pl-4">
        <h3 className="text-lg font-bold leading-6 text-primary">
          User Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm tg-lgray">Update user details.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-4 flex flex-col items-center">
        <div>
          <Avatar
            src={userInfo.profilePicture}
            file={file}
            setter={setFile}
            label={"Upload a file"}
          />
        </div>
        <div className="w-full mb-2.5">
          <label className="tg-lgray font-semibold">User Id</label>
          <div className="text-primary font-bold w-full outline-none rounded-md border border-2 py-2 px-2 h-10 bg-transparent focus:border-primary">
            {userInfo._id ?? ""}
          </div>
        </div>
        <Input
          className="h-10 bg-transparent focus:border-primary"
          labelName={"User Name"}
          value={formState.name}
          onChange={(e) => {
            setFormState((prev) => ({
              ...prev,
              name: e.target.value,
            }));
          }}
        />
        <div className="w-full my-1.5">
          <label className="tg-lgray font-semibold">Branch</label>
          <select
            className=" h-10 w-full outline-none rounded-lg border-2 tg-lgray px-2 "
            onChange={(e) => {
              setFormState((prev) => ({
                ...prev,
                branch: e.target.value,
              }));
            }}
          >
            <option selected disabled hidden>
              {userInfo.branch ?? "Select a branch"}
            </option>
            <option>KathMandu</option>
            <option>Biratnagar</option>
            <option>Pokhara</option>
            <option>Chitwan</option>
            <option>Damuli</option>
          </select>
        </div>
        <div className="w-full mb-2.5">
          <label className="tg-lgray font-semibold">Role</label>
          <div className="tg-lgray capitalize font-bold w-full outline-none rounded-md border border-2 py-2 px-2 h-10 bg-transparent focus:border-primary">
            {userInfo.role ?? ""}
          </div>
        </div>
      </div>
      <SubmitModalButton
        onClose={formCloseHandler}
        cancelRef={cancelRef}
        isLoading={isLoading}
        submitHandler={() => {
          submitHandler({ ...formState, profilePicture: file });
        }}
      />
    </>
  );
};

const SubmitModalButton = ({
  isLoading,
  onClose,
  cancelRef,
  submitHandler,
}) => {
  return (
    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
      <button
        type="submit"
        className="inline-flex w-full justify-center border border-transparent variant-default-btn px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={() => {
          submitHandler();
        }}
      >
        {isLoading ? "Loading.." : "Submit"}
      </button>
      <button
        type="button"
        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={onClose}
        ref={cancelRef}
      >
        Cancel
      </button>
    </div>
  );
};
