import Lottie from "react-lottie";
import LoadingJson from "./loading.json";
import Spinner from "./spinner.json";
import Image from "../Image";
import { classNames } from "../../utils/classes";

const Loading = ({ height = 200, width = 200, className = "", ...props }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={className}>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isClickToPauseDisabled={true}
        {...props}
      />
    </div>
  );
};
export default Loading;
export const SpinnerLoading = ({
  className = "",
  height = 100,
  width = 100,
  ...props
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Spinner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={className}>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isClickToPauseDisabled={true}
        {...props}
      />
    </div>
  );
};
