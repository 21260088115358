import { AuthWrapper } from "../../routes/page-wrapper";
import { Outlet } from "react-router-dom";
import AdminNav from "../DashNav";
import SideBar from "../DashSidebar";
const StudentLayout = () => {
  return (
    <AuthWrapper>
      {({ routeObject }) => {
        return (
          <>
            <AdminNav />
            <div className="flex h-[87vh] overflow-hidden">
              <div className="w-[5rem]">
                <SideBar />
              </div>
              <div className="px-4 py-2 w-full bg-grayLight overflow-y-scroll">
                <div className="text-2xl text-primary font-bold uppercase">
                  {routeObject.name}
                </div>
                <div className="w-full py-3 ">
                  <Outlet />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </AuthWrapper>
  );
};

export default StudentLayout;
