import Img from "../../components/Image";
import { BiMenu } from "react-icons/bi";
import { navigationLinks } from "./links";
import { classNames } from "../../utils/classes";
import Link from "../../components/Button/LinkButton";
import { Disclosure } from "@headlessui/react";
import { authSelector } from "../../store/auth/auth_slice"
import { useSelector } from "react-redux";

const Nav = () => {
  const { userInfo } = useSelector(state => state.auth);
  const pathName = window.location.pathname;
  return (
    <Disclosure as="nav" className="w-full bg-white">
      <div className="w-full border border-gray px-4 py-2 flex items-center  justify-between ">
        <Link to="/">
          <Img
            src="/assets/xavierlogo.png"
            alt="Logo"
            className="w-[60px] h-[60px] cursor-pointer"
          />
        </Link>
        <div className="hidden md:flex gap-4 items-center ">
          {navigationLinks.map((link) => {
            return (
              <Link
                to={link.href}
                as={"div"}
                key={link.name}
                className={classNames(
                  "tg-lgray text-lg hover:text-primary hover:cursor-pointer",
                  pathName === link.href && "text-primary"
                )}
              >
                {link.name}
              </Link>
            );
          })}
          <Link
            as={"button"}
            className="w-32 variant-default-btn "
            to={
              userInfo !== null ? `/dashboard/${userInfo?.role}/home` : "/signup"
            }
          >
            {userInfo !== null ? "Dashboard" : "Account"}
          </Link>
        </div>
        <div className="block md:hidden">
          <Disclosure.Button>
            <span className="sr-only">Open main menu</span>
            <BiMenu className="w-10 h-10 text-primary" />
          </Disclosure.Button>
        </div>
      </div>

      <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pt-2 pb-3">
          {navigationLinks.map((item) => (
            <Link
              key={item.name}
              className={classNames(
                "tg-lgray hover:bg-primary hover:text-white",
                "block px-3 py-2 rounded-md text-base font-medium outline-primary"
              )}
              to={item.href}
            >
              <Disclosure.Button as={"div"}>{item.name}</Disclosure.Button>
            </Link>
          ))}
          <Link
            className={classNames(
              "bg-primary text-white",
              "block px-3 py-2 mt-2 rounded-md text-base font-medium outline-primary"
            )}
            to={
              userInfo !== null ? `/dashboard/${userInfo?.role}/home` : "/signup"
            }
          >
            <Disclosure.Button as={"div"}>
              {userInfo !== null ? "Dashboard" : "Account"}
            </Disclosure.Button>
          </Link>
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};
export default Nav;
