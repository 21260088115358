import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'
export const currentUser = createAsyncThunk('auth/getUser', async () => {
  const { data } = await api.get('/api/v1/auth/current_user')
  await localStorage.setItem('userInfo', JSON.stringify(data.user))
  return data?.user
})
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userIsLoading: false,
    userInfo: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(currentUser.pending, (state, action) => {
      state.userIsLoading = true
    })
    builder.addCase(currentUser.fulfilled, (state, action) => {
      state.userIsLoading = false
      state.userInfo = action.payload
    })
  },
})
export default authSlice.reducer
const safeSelect = (state) => state
export const authSelector = createSelector(safeSelect, (state) => state.auth)
