import { Link as RouterLink } from "react-router-dom";

const Link = ({ to = "/", as = "span", children, ...rest }) => {
  const Component = as;
  return (
    <RouterLink to={to}>
      <Component {...rest}>{children}</Component>
    </RouterLink>
  );
};
export default Link;
