import { Suspense, useEffect } from "react";
import Loading from "../components/Loading";
import { matchPath, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { element } from "./routes";
import { ToastContainer } from "react-toastify";
import { useCurrentUserQuery } from "../store/auth/auth_service";
import { currentUser } from "../store/auth/auth_slice";

const PageWrapper = ({ route }) => {
  const { element } = route;
  const Component = element;
  return (
    <Suspense
      fallback={
        <Loading
          className={"h-[90vh] w-full flex justify-cener items-center"}
        />
      }
    >
      <Component />
      <ToastContainer />
    </Suspense>
  );
};
export default PageWrapper;

const getRouteObject = (wanted) => {
  const allRoutes = [...element];
  const routeObj = allRoutes.find((route) => {
    const match = matchPath(wanted, route.path);
    if (match) return route;
    return null;
  });
  let doc;
  const nestedCheck = [...allRoutes].map((route) => {
    return route.children.find((nestedRoute) => {
      const deepMatch = matchPath(wanted, route.path + nestedRoute.path);
      if (deepMatch) {
        doc = {
          role: route.role,
          ...nestedRoute,
        };
        return;
      }
    });
  });

  const obj = doc ? doc : routeObj ? routeObj : null;
  return obj;
};

export const AuthWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const routeObject = getRouteObject(pathname);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    dispatch(currentUser());
  }, [user]);

  if (user === null || !user) {
    return <Navigate to={"/login"} replace={true} />;
  }
  if (user && user.role !== routeObject.role) {
    return <Navigate to={"/login"} replace={true} />;
  }
  return user && children({ routeObject: routeObject });
};
