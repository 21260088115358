import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../components/Button/LinkButton";
import Image from "../../components/Image";
import { IoMdSettings } from "react-icons/io";
import Setting from "./components/Settings";
import { useUploadFileMutation, useUpdateProfileMutation } from "../../store/users/user_service";
import toast from "../../utils/toast"
import { authSelector } from "../../store/auth/auth_slice";

const AdminNav = () => {
  const { userInfo } = useSelector(state => state.auth);
  console.log(userInfo)
  const [upload] = useUploadFileMutation();
  const [update, { isLoading: updateLoading }] = useUpdateProfileMutation();
  const [showUserModal, setShowUserModal] = useState(false);
  async function updateUser(payload) {
    const formData = new FormData();
    const payloadFile = payload.profilePicture
    await formData.append("image", payloadFile);
    if (payloadFile) {
      const { data: file } = await upload({ file: formData });
      const { data } = await update({
        ...payload,
        profilePicture: file
      });
      if (data?.success) {
        toast.success("Succesfully added")
      }
      return;
    }
    const doc = {
      name: payload.name,
      branch: payload.branch
    }
    const { data } = await update({
      ...doc,
    });
    if (data.success) {
      toast.success("Succesfully added")
    }
  }
  function modalChangeHandler() {
    setShowUserModal((prev) => !prev);
  }
  return (
    <>
      <div className="w-full">
        <div className="w-full border border-gray px-4 py-2 flex items-center  justify-between ">
          <div className="flex justify-center items-center gap-3">
            <Link
              to="/"
              as={"div"}
              className="w-[60px] h-[60px] cursor-pointer"
            >
              <Image
                src="/assets/xavierlogo.png"
                className="w-full h-full object-cover"
              />
            </Link>
            <div className="flex flex-col justify-center ">
              <div className="text-sm md:text-lg font-bold text-primary">
                Xaiver International Education
              </div>
              <div className="text-sm md:text-base font-bold tg-lgray">
                {userInfo?.role === "admin" ? "Admin Portal" : "Student Portal"}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2">
            <div className="tg-lgray">
              <IoMdSettings
                className="text-gray-400 h-[20px] w-[20px] cursor-pointer"
                onClick={modalChangeHandler}
              />
            </div>
            <button className="h-[60px] cursor-pointer w-[60px] rounded-full overflow-hidden focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 ">
              <Image
                className="h-full w-full object-cover"
                src={
                  userInfo?.profilePicture !== ""
                    ? userInfo?.profilePicture
                    : "/assets/user.png"
                }
              />
            </button>
            <div className="flex flex-col">
              <p className="font-bold text-primary text-sm">{userInfo?.name}</p>
              <p className="tg-lgray text-sm">{userInfo?.branch}</p>
            </div>
          </div>
        </div>
      </div>
      {!!showUserModal && (
        <Setting
          userInfo={userInfo}
          isOpen={showUserModal}
          onClose={modalChangeHandler}
          setter={setShowUserModal}
          isLoading={updateLoading}
          submitFormHandler={updateUser}
        />
      )}
    </>
  );
};
export default AdminNav;
