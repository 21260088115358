import { Component } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  matchPath,
  useLocation,
} from 'react-router-dom'
import PageWrapper from './page-wrapper'
import { element } from './routes'

const PageRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {element.map((route, i) => {
          const LayoutElement = route.element
          return (
            <Route key={route.path} path={route.path} element={LayoutElement}>
              {route.children.map((child) => {
                return (
                  <Route
                    key={child.path}
                    path={child.path}
                    element={
                      <PageWrapper route={{ ...child, role: route.role }} />
                    }
                  />
                )
              })}
            </Route>
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}
export default PageRoutes
