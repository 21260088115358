import { apiSlice } from "../../utils/api";

export const extendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials) => ({
        url: "/api/v1/auth/register",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res) => {
        let token = res.token;
        localStorage.setItem("token", JSON.stringify(token));
        return { ...res }
      },
      transformErrorResponse: ({ data, status }) => {
        return { ...data };
      },

    }),
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: { email, password },
      }),
      transformResponse: (res) => {
        localStorage.setItem("token", JSON.stringify(res?.token));
        return res;
      },
      transformErrorResponse: (error) => {
        return { ...error.data };
      },
    }),
    currentUser: builder.query({
      query: () => ({
        url: "/api/v1/auth/current_user",
      }),
      transformResponse: res => {
        localStorage.setItem("userInfo", JSON.stringify(res.user));
        return res;
      },
      // onQueryStarted: async (arg, { queryFulfilled }) => {
      //   await queryFulfilled.then(({ data }) => {
      //     setTimeout(() => {
      //       return data;
      //     }, 500);
      //   });
      // },
    }),
  }),
});
export const {
  useSignupMutation,
  useLoginMutation,
  useCurrentUserQuery
} = extendedSlice;
