import { configureStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import {
  AuthReducer,
} from "./rootReducer";
import { apiSlice } from "../utils/api";
import thunk from "redux-thunk";
const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialStore = {
  auth: { userInfo: userInfoFromStorage },
};
const store = configureStore({
  preloadedState: initialStore,
  reducer: {
    auth: AuthReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware), ...middleware,
  devTools: true,
  // middleware: [...middleware],
});
export default store;
