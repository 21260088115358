import Nav from "../Nav";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
const LandingLayout = ({ children }) => {
  return (
    <div className="w-screen h-screen bg-grayLight overflow-y-scroll">
      <Nav />
      <div className="w-full ">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
export default LandingLayout;
