import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillTwitterSquare } from "react-icons/ai";
import { ImWhatsapp } from "react-icons/im";
import { footerLinks } from "./footerLinks";
import Link from "../../components/Button/LinkButton";

const Footer = () => {
  return (
    <footer className="flex flex-col justify-center px-6 pt-4 w-full bg-primary ">
      <div className="w-full py-6 px-6 flex flex-col md:flex-row gap-10 items-center justify-center md:items-start">
        {footerLinks.map((link) => {
          return (
            <div
              className="flex flex-col justify-center md:justify-start items-center md:items-start"
              key={link.name}
            >
              <div className="font-bold text-white text-xl">{link.name}</div>
              {link.subLinks.map((subLink) => {
                return (
                  <Link
                    className="font-light text-white text-lg hover:underline"
                    key={subLink.name}
                    to={subLink.href}
                    as={"div"}
                  >
                    {subLink.name}
                  </Link>
                );
              })}
            </div>
          );
        })}
        <div className="flex flex-col justify-center md:justify-start items-center md:items-start">
          <div className="font-bold text-white text-xl">Stay Updated</div>
          {/* <div className="flex justify-center items-center mt-4"> */}
          {/*   <input */}
          {/*     className="h-10 py-2 px-2 w-full md:w-72 placeholder:text-white rounded-lg text-white rounded-r-none bg-transparent border border-white outline-none" */}
          {/*     placeholder="Enter Email" */}
          {/*   /> */}
          {/*   <button className="h-10 py-2 px-2 bg-white rounded-l-none rounded-lg font-bold text-primary"> */}
          {/*     Subscribe */}
          {/*   </button> */}
          {/* </div> */}
          <div className="flex justify-center pt-2 items-center gap-4">
            <FaFacebookSquare className="text-white cursor-pointer" size={32} />
            <BsInstagram className="text-white cursor-pointer" size={30} />
            <AiFillTwitterSquare
              className="text-white cursor-pointer"
              size={35}
            />
            <ImWhatsapp className="text-white cursor-pointer" size={30} />
          </div>
        </div>
      </div>
      <div className="w-full mt-4 h-[1px] bg-white border-white" />
      <div className="w-full text-white py-4 font-light flex justify-center items-center">
        Copyright © 2022 XavierInternationalEdu
      </div>
    </footer>
  );
};
export default Footer;
