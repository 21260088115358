import { lazy } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import LandingLayout from "../layouts/LandingLayout";
import AdminLayout from "../layouts/AdminLayout";
import StudentLayout from "../layouts/StudentLayout";

export function lazyLoad(page) {
  const LazyComp = lazy(() => import(`../screens/${page}`));
  return LazyComp;
}
const NonLayout = () => {
  return (
    <div className="bg-grayLight">
      <Outlet />
    </div>
  );
};

const routes = [
  {
    href: "/",
    component: lazyLoad("home"),
    name: "Home",
    private: false,
  },
  {
    href: "/blogs",
    component: lazyLoad("blogs"),
    name: "Blogs",
    private: false,
  },
  {
    href: "/abroad-study",
    component: lazyLoad("study-abroad"),
    name: "Study Aborad",
    private: false,
  },
  {
    href: "/abroad-study/:id",
    component: lazyLoad("country-details"),
    name: "Country",
    private: false,
  },
  {
    href: "/login",
    component: lazyLoad("login"),
    name: "Login",
    private: false,
  },
  {
    href: "/signup",
    component: lazyLoad("signup"),
    name: "Signup",
    private: false,
  },
  {
    href: "/book-appoinment",
    component: lazyLoad("book-appoinment"),
    name: "Book Appoinment",
    private: true,
    role: "student",
  },
  {
    href: "/branch",
    component: lazyLoad("branches"),
    name: "branches",
    private: false,
    role: "student",
  },
  {
    href: "/test",
    component: lazyLoad("test"),
    name: "Book Appoinment",
    private: false,
  },
];

let element = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "/",
        element: lazyLoad("home"),
        name: "Home",
      },
      {
        path: "blogs",
        element: lazyLoad("blogs"),
        name: "Blogs",
      },
      {
        path: "branch",
        element: lazyLoad("branches"),
        name: "Branch",
      },
      {
        path: "abroad-study",
        element: lazyLoad("study-abroad"),
        name: "Study Abroad",
      },
      {
        path: "abroad-study/:id",
        element: lazyLoad("country-details"),
        name: "Study Abroad",
      },
      {
        path: "book-appoinment",
        element: lazyLoad("book-appoinment"),
        name: "Book Appoinment",
      },
    ],
  },
  {
    path: "/",
    element: <NonLayout />,
    children: [
      {
        path: "login",
        element: lazyLoad("login"),
        name: "Login",
      },
      {
        path: "signup",
        element: lazyLoad("signup"),
        name: "Signup",
      },
    ],
  },
  {
    path: "/dashboard/student/",
    element: <StudentLayout />,
    role: "student",
    children: [
      {
        path: "home",
        element: lazyLoad("student-dashboard"),
        name: "Dashboard",
      },
      {
        path: "countries",
        element: lazyLoad("student-countries"),
        name: "Countries",
      },
      {
        path: "events",
        element: lazyLoad("student-events"),
        name: "Events",
      },
    ],
  },
  {
    path: "/dashboard/admin/",
    element: <AdminLayout />,
    role: "admin",

    children: [
      {
        path: "home",
        element: lazyLoad("admin-dashboard"),
        name: "Analytics",
      },
      {
        path: "appoinment",
        element: lazyLoad("admin-appoinments"),
        name: "Appoinments",
      },
      {
        path: "users",
        element: lazyLoad("admin-users"),
        name: "Our Clients",
      },
      {
        path: "countries",
        element: lazyLoad("admin-countries"),
        name: "Countries",
      },
      {
        path: "events",
        element: lazyLoad("admin-events"),
        name: "Events",
      },
    ],
  },
];

export { routes, element };