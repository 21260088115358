import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Image from "../Image";
import FileUploader from "../Input/FileUploader";

const Avatar = ({ src, setter, file, label }) => {
  const [image, setImage] = useState("");

  function checkFileType(file) {
    if (!file) return false;
    let fileTypes = ["jpg", "jpeg", "png"];
    let extension = file.name.split(".").pop().toLowerCase();
    if (fileTypes.indexOf(extension) > -1 === false) {
      toast.error("File must be png or jpeg");
      return false;
    }
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setImage(reader.result);
    };
    return true;
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <button className=" h-[80px] cursor-pointer w-[80px] rounded-full overflow-hidden focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
        <Image
          key={image}
          className="h-full w-full object-cover"
          src={
            checkFileType(file) ? image : src !== "" ? src : "/assets/user.png"
          }
        />
      </button>
      <FileUploader label={label} setter={setter} />
    </div>
  );
};
export default Avatar;
